<template>
  <div class="chat-list">
    <v-card
      v-for="(chat, index) in chats"
      :key="index"
      class="chat-list-item mb-3"
      :class="{active: currentChat && chat.id == currentChat.id}"
      :link="!!chat.id"
      @click="$emit('changeChat', chat.id)"
      tile
      v-show="chat.is_visible"
      :id="`chat-list-item-${chat.id}`"
    >
      <v-card-title>

        <div v-if="toSupport" class="chat-searchable">
          {{getCompanyName(chat.lc_manager ? chat.lc_manager : chat.ds_manager)}}<br>
          {{getUserName(chat.lc_manager ? chat.lc_manager : chat.ds_manager)}}
          <v-spacer></v-spacer>
          <v-badge class="mr-2" v-if="chat.notViewed" :content="chat.notViewed"></v-badge>
        </div>
        <v-row v-else>
          <v-col sm="8" class="chat-searchable">
            {{getCompanyName(chat.lc_manager)}}<br>
            {{getUserName(chat.lc_manager)}}
            <v-divider></v-divider>
            {{getCompanyName(chat.ds_manager)}}<br>
            {{getUserName(chat.ds_manager)}}
          </v-col>
          <v-col sm="4" class="pa-0 text-right">
            <span class="request-id">Запрос {{chat.request_id}}</span><br>
            <v-badge class="mr-4 mt-2" v-if="chat.notViewed" :content="chat.notViewed"></v-badge>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="chat.last_message">{{chat.last_message.text}}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="chat.last_message">
        {{formatDate(chat.last_message.created_at)}}
        <v-spacer></v-spacer>
        <span v-if="!chat.last_message.support_viewed" class="chat-green">Новое</span>
        <span v-if="chat.last_message.support_viewed" class="chat-grey">Просмотрено</span>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  props: ['currentChat', 'chats', 'toSupport'],

  methods: {

    getUserName(user) {
      return (user.last_name ? user.last_name + ' ' : '') + user.first_name
    },

    getCompanyName(user) {
      if (user.dealership) {
        return user.dealership[0].name
      } else {
        return user.leasing_company[0].name
      }
    },

    formatDate(date) {
      return moment(date).format('LLL')
    }
  }
}
</script>
