<template>
  <v-sheet data-height="windowHeight - 566" class="chat-message-sheet">
    <v-card class="chat-message" tile v-for="(item, index) in messages" :key="index" :color="getColor(item)">
      <v-card-title>
        <div>
          <span v-if="item.to_support && item.to_user_id" class="text--black">Служба поддержки</span>
          <span v-if="item.to_support && !item.to_user_id">{{item.user.first_name}} {{item.user.last_name}}</span>
          <span v-if="!item.to_support">{{item.user.last_name}} {{item.user.first_name}}</span>
        </div>
        <div class="ml-5 chat-message-date">{{formatDate(item.created_at)}}</div>
        <v-spacer></v-spacer>
        <div v-if="item.user_id == $store.state.auth.user.id">
          <v-icon color="blue" v-if="item.viewed">mdi-check-all</v-icon>
          <v-icon v-if="!item.viewed">mdi-check-all</v-icon>
        </div>
      </v-card-title>
      <v-card-text>{{item.text}}</v-card-text>
    </v-card>
  </v-sheet>
</template>
<script>
import moment from 'moment'

export default {
  props: ['messages', 'windowHeight'],

  methods: {
    formatDate(date) {
      return moment(date).format('LLL')
    },
    getColor(item) {
      return item.archive_message ? 'red lighten-4' : ''
    }
  }
}
</script>
