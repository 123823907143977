<template>
  <div>
    <div class="d-flex mb-5">
      <div class="d-flex flex-column">
        <h5 class="h5-text">Онлайн чат</h5>
      </div>
    </div>
    <v-row>
      <v-col md="3" sm="12" class="page-block flex-column" v-if="chatsVisible">
        <div>
          <ChatSearch @search="search" @clear="clearSearch" :supportChats="toSupport"></ChatSearch>
        </div>
        <div>
          <v-row class="mb-3">
            <v-col xl="6" lg="12" md="12" sm="12">
              <v-btn color="primary" block :outlined="!toSupport" @click="switchChatsType(true)">Сообщения</v-btn>
            </v-col>
            <v-col xl="6" md="12" sm="12">
              <v-btn color="primary" block :outlined="toSupport" @click="switchChatsType(false)">Переписки</v-btn>
            </v-col>
          </v-row>
          <ChatList :chats="chats" :currentChat="currentChat" :toSupport="toSupport" @changeChat="changeChat"></ChatList>
        </div>
      </v-col>
      <v-col md="6" sm="12" class="page-block flex-column" v-if="messagesVisible">
        <div v-if="currentChat" class="position-relative" data-v-resize="setHeight">
          <h3 class="d-flex chat-messages-header" v-if="toSupport">
            <v-btn v-if="isMobile" :class="{overflowed: isMobile}" text @click="backToChats()">
              <v-icon>mdi-chevron-left</v-icon> {{getUserName(currentChat.lc_manager ? currentChat.lc_manager : currentChat.ds_manager)}}
            </v-btn>
            <span v-if="!isMobile">{{getUserName(currentChat.lc_manager ? currentChat.lc_manager : currentChat.ds_manager)}}</span>
            <v-spacer></v-spacer>
            <v-btn v-if="isMobile" icon @click="toInfo()"><v-icon>mdi-information-outline</v-icon></v-btn>
          </h3>
          <h3 class="d-flex chat-messages-header" v-if="!toSupport">
            <v-btn v-if="isMobile" :class="{overflowed: isMobile}" text @click="backToChats()">
              <v-icon>mdi-chevron-left</v-icon> {{getCompanyName(currentChat.lc_manager)}} - {{getCompanyName(currentChat.ds_manager)}}
            </v-btn>
            <span v-if="!isMobile">{{getCompanyName(currentChat.lc_manager)}} - {{getCompanyName(currentChat.ds_manager)}}</span>
            <v-spacer></v-spacer>
            <v-btn v-if="isMobile" icon @click="toInfo()"><v-icon>mdi-information-outline</v-icon></v-btn>
          </h3>
          <v-divider class="mt-5 mb-5"></v-divider>
          <MessageList :messages="messages" :window-height="windowHeight"></MessageList>
          <v-form
            class="mt-5 position-relative"
            @submit.prevent="send"
            ref="messageForm"
            lazy-validation
            v-model="messageFormValid"
          >
            <div>
              <v-textarea
                :rules="messageRules"
                v-model="message"
                rows="3"
                :counter="charCounter"
                outlined
                dense
                @blur="$refs.messageForm.resetValidation()"
              ></v-textarea>
            </div>
            <div>
              <v-btn color="indigo darken-4" class="white--text" type="submit" large block data-click="send">Отправить сообщение</v-btn>
            </div>
            <v-overlay
              :value="formBlocked"
              :absolute="true"
              class="relative-overlay"
              v-if="!toSupport"
            >
              <v-btn icon fab absolute right @click="formBlocked = false" color="black">
                <v-icon>mdi-lock-open</v-icon>
              </v-btn>
            </v-overlay>
          </v-form>
          <v-overlay :value="loading" absolute>
            <v-progress-circular
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
        </div>
      </v-col>
      <v-col md="3" sm="12" class="page-block77 pt-0 flex-column" v-if="infoVisible">
        <div>
          <h3 class="d-flex" v-if="isMobile">
            <v-btn text @click="backToMessages()"><v-icon>mdi-chevron-left</v-icon> Подробнее</v-btn>
          </h3>
          <v-divider v-if="isMobile" class="mb-3"></v-divider>
          <v-card v-if="currentChat" class="chat-info" elevation="0" tile>
            <v-card-title v-if="toSupport">{{getInfoHeader(currentChat)}}</v-card-title>
            <v-card-subtitle v-if="toSupport">
              <div class="company-name mt-3">{{getCompanyName(currentChat.lc_manager ? currentChat.lc_manager : currentChat.ds_manager)}}</div>
              <div class="company-location">{{getCompanyLocation(currentChat.lc_manager ? currentChat.lc_manager : currentChat.ds_manager)}}</div>
              <div class="user-name mt-3">{{getUserName(currentChat.lc_manager ? currentChat.lc_manager : currentChat.ds_manager )}}</div>
              <div class="company-location">{{currentChat.lc_manager ? currentChat.lc_manager.email : currentChat.ds_manager.email}}</div>
              <div class="company-location">{{currentChat.lc_manager ? currentChat.lc_manager.phone : currentChat.ds_manager.phone}}</div>
              <div class="company-location mt-3">
                Регистрация<br>
                {{currentChat.lc_manager ? formatDate(currentChat.lc_manager.created_at) : formatDate(currentChat.ds_manager.created_at) }}
              </div>
            </v-card-subtitle>
            <v-card-title v-if="!toSupport">
                {{getCompanyName(currentChat.lc_manager)}}
            </v-card-title>
            <v-card-subtitle v-if="!toSupport">{{getUserName(currentChat.lc_manager)}}</v-card-subtitle>
            <v-card-title v-if="!toSupport">
              {{getCompanyName(currentChat.ds_manager)}}
            </v-card-title>
            <v-card-subtitle v-if="!toSupport">{{getUserName(currentChat.ds_manager)}}</v-card-subtitle>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import Vue from 'vue'
// import Component from 'vue-class-component'
import WS from '@/apis/WS'
import { Http } from '@/apis/Http'
import moment from 'moment'
import ChatSearch from '@/views/Chat/components/ChatSearch.vue'
import ChatList from '@/views/Admin/Chats/components/AdminChatList.vue'
import MessageList from '@/views/Admin/Chats/components/MessageList.vue'
import CommonMethods from '@/views/Chat/components/CommonMethods'

export default {

  components: { ChatList, MessageList, ChatSearch },
  data() {
    return {
      loading: false,
      charCounter: 1000,
      messageFormValid: true,
      windowHeight: window.innerHeight,
      message: '',
      messages: [],
      toSupport: true,
      currentChat: null,
      messagesVisible: true,
      infoVisible: true,
      isMobile: false,
      formBlocked: true,
      chats: [],
      isArchived: 0
    }
  },
  async created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.messagesVisible = false
      this.infoVisible = false
      this.isMobile = true
    }
    this.$setLoading(true)
    if (this.$route.query.responseId) {
      this.responseId = parseInt(this.$route.query.responseId)
      this.messagesVisible = true
    }

    if (this.$route.query.requestId) {
      this.requestId = parseInt(this.$route.query.requestId)
      this.messagesVisible = true
    }

    if (this.$route.params.chat_id) {
      this.messagesVisible = true
    }

    if (this.$route.query.user_id) {
      this.userId = parseInt(this.$route.query.user_id)
      this.messagesVisible = true
    }

    await this.getChatDetails()
    await this.getMessages()
    if (this.currentChat) this.toSupport = !!this.currentChat.to_support

    this.$setLoading(false)

    this.listenOfArchive()

    this.$socket.on('new_message', (data) => {
      if (this.$route.name !== 'Chats') return
      console.log('nm', data)

      const chat = this.chats.find(el => el.id === data.chat_id)
      if (chat) {
        if (this.currentChat && data.chat_id !== this.currentChat.id) ++chat.notViewed
        chat.last_message = {
          id: data.id,
          text: data.text,
          support_viewed: 0,
          created_at: data.created_at
        }
      } else if (!!data.to_support === this.toSupport) {
        Http.get('/chat/get-chat-by-id', {
          params: {
            id: data.chat_id
          }
        }).then(response => {
          if (response?.data) {
            this.chats.splice(0, 0, response.data)
          }
        }).catch(e => console.log(e))
      }

      if (this.currentChat && data.chat_id === this.currentChat.id) {
        data.viewed = 1
        if (data.to_support && data.to_user_id && data.to_user_id[0] === null) data.to_user_id = 0
        this.messages.push(data)
        this.sendNewViewed(data.id)
      }
      if (this.chats.length) this.sortByNew()
    })

    this.$socket.on('is_viewed', (data) => {
      if (this.$route.name !== 'Chats') return
      console.log('isv', data)
      for (const id of data.ids) {
        const message = this.messages.find(el => el.id === id)
        if (message) message.viewed = 1
      }
      this.setLastMessageViewed(data.ids)
    })

    this.$listenDocumentVisibility(() => {
      this.sendAllViewed()
    })
  },

  computed: {
    messageRules() {
      return [
        (v) => !!v || 'Введите сообщение',
        (v) => (v && v.length <= this.charCounter) || `Максимальная длина сообщения ${this.charCounter} символов`
      ]
    },
    chatsVisible() {
      if (!this.isMobile) return true
      return !this.messagesVisible && !this.infoVisible
    }
  },

  methods: {
    async switchChatsType(toSupport) {
      if (this.$router.currentRoute.path !== '/admin/chats') {
        this.$router.push('/admin/chats')
      }
      this.formBlocked = true
      this.currentChat = null
      this.chats = []
      this.toSupport = toSupport
      await this.getChatDetails()
      this.getMessages()
    },

    setChatUrl() {
      let url = ''
      if (this.currentChat) url = `/admin/chats/${this.currentChat.id}`
      if (url && url !== this.$router.currentRoute.path) this.$router.push(url)
    },

    sendAllViewed() {
      // if (!this.$isDocumentVisible()) return
      const ids = []
      for (const message of this.messages) {
        if (!message.viewed) {
          ids.push(message.id)
          message.viewed = 1
        }
      }
      if (ids.length) WS.sendMessage('is_viewed', { to_user_id: this.currentChat.user_id, ids: ids })
      this.$store.commit('setViewed', ids)
    },

    sendNewViewed(id) {
      // if (!this.$isDocumentVisible()) return
      WS.sendMessage('is_viewed', { to_user_id: this.currentChat.user_id, ids: [id] })
      this.$store.commit('setViewed', [id])
    },

    send() {
      const toUserId = this.currentChat.to_support
        ? [this.currentChat.lc_manager ? this.currentChat.lc_manager.id : this.currentChat.ds_manager.id]
        : [this.currentChat.lc_manager.id, this.currentChat.ds_manager.id]
      const data = {
        id: 0,
        viewed: 0,
        text: this.message,
        ds_manager: this.currentChat.ds_manager,
        lc_manager: this.currentChat.lc_manager,
        user_id: this.$store.state.auth.user.id,
        request_id: this.currentChat.request_id,
        to_support: 1,
        chat_id: this.currentChat.id,
        to_user_id: toUserId,
        created_at: moment()
      }

      this.addMessage(data)
      Http.post('/chat/store', data).then(response => {
        if (response.data.error) {
          data.user_id = 0
          data.text = response.data.error
          data.archive_message = true
          data.user = {
            first_name: 'Сообщение',
            last_name: ''
          }
          return
        }
        data.id = response.data.message_id
        data.chat_id = response.data.chat_id

        this.currentChat.last_message = {
          id: data.id,
          text: data.text,
          viewed: 0,
          created_at: data.created_at
        }

        WS.sendMessage('new_message', data)
        this.message = ''
        this.$refs.messageForm.resetValidation()
      })
    },

    getInfoHeader(chat) {
      return chat.ds_manager ? 'Дилерский центр' : 'Лизинговая компания'
    },

    getUserName(user) {
      if (!user) return ''
      return (user.last_name ? user.last_name + ' ' : '') + user.first_name
    },

    getCompanyName(user) {
      if (!user) return ''
      if (user.dealership) {
        return user.dealership[0].name
      } else {
        return user.leasing_company[0].name
      }
    },

    getCompanyLocation(user) {
      if (!user) return ''
      if (user.dealership) {
        return user.dealership[0].ds_location.address
      }
      return ''
    },

    ...CommonMethods
  }
}
</script>
<style>
  .chat-message-sheet {
    overflow-y: auto;
  }
</style>
